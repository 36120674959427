<template>
	<Utils ref='Utils' />
	<div class="p-grid crud-demo card" style="margin-right:0px;margin-left: 0px;" v-if='!show_note_editor'>
		<div class="p-col-12">
			<noteOperator @searchHis="searchHis" @hideEditor='hideEditor' @createNewTopic='createNewTopic' :class_name_copy="'p-mb-12'"></noteOperator>
		</div>
	</div>
	<div class="p-grid p-fluid" v-if='!show_note_editor'>
		<div class="p-col-12 p-lg-4" v-for="(listitem, i) of notes_his" :key="i">
			<noteList :note_list_copy='listitem' @deleteNote="deleteNote" @addNewNote="addNewNote" @editeNote="editeNote"></noteList>
		</div>
		<div class="p-col-12 p-lg-4" v-if='notes_his.length==0'>
			<div class="card" style="padding: 30px;">
				没有相应笔记，快点击上方绿色"+"号按钮来创建吧~
			</div>
		</div>
	</div>
	<div class="p-grid p-fluid card" v-show='show_note_editor' :style='container_style'>
		<div class="p-col-12 p-md-4 card-gray" style="overflow: hidden;margin-top: 0px;margin-bottom: 20px;">
			<noteOperator @searchHis="searchHis" @hideEditor='hideEditor' @createNewTopic='createNewTopic' :class_name_copy="'crud-demo card'" style="background-color: #ffffff;"></noteOperator>
			<div :style='his_style' style="margin-bottom: 0px;">
				<div v-for="(listitem, i) of notes_his" :key="i" style="margin-bottom: 10px;">
					<noteList :note_list_copy='listitem'  @deleteNote="deleteNote" @addNewNote="addNewNote" @editeNote="editeNote">
					</noteList>
				</div>
			</div>
		</div>
		<div v-show='show_note_editor' style="height: 100%;padding: 0px;" class="p-col-12 p-md-8">
			<noteEditor ref='this_note_editor' @flushNotesHis="flushNotesHis" style='margin-top: 0px;' />
		</div>
	</div>
</template>

<script>
	// import NetWorkService from '../../service/NetWorkService.js';
	import noteEditor from './noteEditor.vue';
	import noteList from './noteList.vue';
	import noteOperator from './notesOperator.vue';
	import Utils from '../Utils.vue';
	export default {
		emits: ['hideLoginContainer','changeUser','changeNoteWrite', 'toggleSetting', 'menuToggle','closeNoteEditor'],
		name: '',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		components: {
			'noteEditor': noteEditor,
			'noteList': noteList,
			'noteOperator': noteOperator,
			'Utils':Utils,
		},
		data() {
			return {
				dataviewValue: null,
				layout: 'grid',
				sortKey: null,
				sortOrder: null,
				sortField: null,
				his_style: {
					"overflow-y": "auto",
					"overflow-x": "hidden",
					"margin-top": "10px",
					"height": "650px",
				},
				container_style: {
					"overflow-y": "auto",
					"overflow-x": "hidden",
					"height": "700px",
					"margin-bottom":"0px",
					"padding-bottom":"0px",
					"background-color":"#ffsasd",
				},
				sortOptions: [{
						label: 'Price High to Low',
						value: '!price'
					},
					{
						label: 'Price Low to High',
						value: 'price'
					},
				],
				notes_his:[],
				show_note_editor: false,
			}
		},
		networkService: null,
		created() {
			// this.networkService = new NetWorkService();
		},
		beforeRouteEnter (to, from, next) {
			 next(vm => {
				if (vm.$appState.show_note_editor==true){
					vm.$appState.show_note_editor=false;
					vm.$appState.width_num=12;
				}
			 })
		},
		mounted() {
			// console.log('组件加载成功');
			this.resizeWin();
			this.getPersonNotes();
		},
		methods: {
			searchHis(search_words,search_by_note_name){
				if(search_words==''){
					this.getPersonNotes();
				}else{
					if (search_by_note_name==true){
						this.$refs.this_note_editor.searchPersonNotes(search_words);
					}else{
						this.$refs.this_note_editor.searchPersonNotesByParas(search_words);
					}
				}
			},
			hideEditor(){
				this.show_note_editor=false;
			},
			editeNote(item,para) {
				if (this.$appState.show_note_editor==true){
					this.$refs.Utils.showError('请关闭侧边栏笔记编辑器后再点击编辑相应笔记');
					return;
				}
				this.show_note_editor=true;
				this.resizeWin();
				this.$refs.this_note_editor.addHtmlToEditor(item.note_id,item.label,para)
			},
			//屏幕变化调整样式
			resizeWin() {
				var windowHeight = document.documentElement.clientHeight;
				this.his_style.height =windowHeight-240+ 'px';
				this.container_style.height =windowHeight-130+ 'px';
				// console.log(this.his_height);
			},
			getPersonNotes(){
				this.$refs.this_note_editor.getPersonNotes();
			},
			flushNotesHis(notes_his){
				this.notes_his=notes_his.slice(1,notes_his.length);
				// this.$router.push({
				// 	name: 'transPage',
				// 	params: {
				// 	  target_path: 'noteStorage', 
				// 	}
				// })
			},
			addNewNote(topic_name){
				this.$refs.this_note_editor.addNewFile('note',topic_name);
			},
			createNewTopic(){
				this.$refs.this_note_editor.addNewFile('topic','topic');
			},
			deleteNote(item){
				this.$refs.this_note_editor.deleteConfirm(item.note_id,item.label.replace(/<.*?>/ig, ""));
			}
		},
	}
</script>

<style scoped lang="scss">
	.word-item {
		display: flex;
		flex-direction: row;
		padding: 2px 0px 2px 0px;
	}

	.word-item:hover {
		// background-color: #1fa1fc;
		color: #1fa1fc;
	}

	.product-name {
		font-size: 1.5rem;
		font-weight: 700;
	}

	.product-description {
		margin: 0 0 1rem 0;
	}

	.product-category-icon {
		vertical-align: middle;
		margin-right: .5rem;
	}

	.product-category {
		font-weight: 600;
		vertical-align: middle;
	}

	::v-deep(.product-list-item) {
		display: flex;
		align-items: center;
		padding: 1rem;
		width: 100%;

		img {
			width: 150px;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
			margin-right: 2rem;
		}

		.product-list-detail {
			flex: 1 1 0;
		}

		.p-rating {
			margin: 0 0 .5rem 0;
		}

		.product-price {
			font-size: 1.5rem;
			font-weight: 600;
			margin-bottom: .5rem;
			align-self: flex-end;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.p-button {
			margin-bottom: .5rem;
		}

	}

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-instock {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-outofstock {
			background: #FFCDD2;
			color: #C63737;
		}

		&.status-lowstock {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

	::v-deep(.product-grid-item) {
		margin: .5em;
		border: 1px solid #dee2e6;

		.product-grid-item-top,
		.product-grid-item-bottom {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		img {
			width: 75%;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
			margin: 2rem 0;
		}

		.product-grid-item-content {
			text-align: center;
		}

		.product-price {
			font-size: 1.5rem;
			font-weight: 600;
		}
	}

	@media screen and (max-width: 576px) {
		.product-list-item {
			flex-direction: column;
			align-items: center;

			img {
				width: 75%;
				margin: 2rem 0;
			}

			.product-list-detail {
				text-align: center;
			}

			.product-price {
				align-self: center;
			}

			.product-list-action {
				display: flex;
				flex-direction: column;
			}

			.product-list-action {
				margin-top: 2rem;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				width: 100%;
			}
		}
	}
</style>
