<template>
	<Toolbar :class="class_name">
		<template v-slot:left>
			<div class="p-inputgroup" id="search_note_hsi_box">
				<span class="p-input-icon-right">
					<InputText autocomplete="off" style="width:100%;" v-model="search_his_words"
						v-on:keyup.enter="searchHis" type="text" placeholder="混合查询笔记内容" />
					<i class="pi pi-times" style="opacity: 0.7;" @click.stop="InitialWords" title='清空输入框' />
				</span>
				<span class="p-inputgroup-addon" @click="searchHis">
					<i class="pi pi-search" title='查询'></i>
				</span>
			</div>
		</template>
		<template v-slot:right>
			<div class='p-mr-2' title='展示搜索的细节结果' style="display:flex;flex-direction: row;align-items: center;">
				<span class='p-mr-2'>展示搜索结果</span>
				<InputSwitch v-model="show_search_results" @change='changeShowSearchResult' />
			</div>
<!-- 			<div class='p-mr-2' title='不搜索文章内容,只按文章标题搜索' style="display:flex;flex-direction: row;align-items: center;">
				<span class='p-mr-2'>仅搜索标题</span>
				<InputSwitch v-model="search_by_note_name" />
			</div> -->
			<Button icon="pi pi-plus" class="p-button-success p-mr-2" @click="createNewTopic" title='新增专题' />
			<!-- <Button icon="pi pi-cloud-upload" class="p-d-inline-block p-mr-2" @click="uploadWords" title='批量保存勾选单词' /> -->
			<Button icon="pi pi-upload" class="p-button-danger" @click="hideEditor" title="关闭笔记编辑器" />
		</template>
	</Toolbar>
</template>

<script>
	import NetWorkService from '../../service/NetWorkService.js';
	export default {
		emits: ['hideLoginContainer','changeUser','changeNoteWrite', 'toggleSetting', 'menuToggle'],
		name: 'notesOperator',
		props: {
			class_name_copy:{
				default:'p-mb-12',
				type:String
			}
		},
		data() {
			return{
				search_his_words:null,
				search_by_note_name:false,
				show_search_results:true,
			}
		},
		networkService:null,
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			this.show_search_results=this.$appState.show_search_results;
		},
		methods: {
			hideEditor(){
				this.$emit('hideEditor');
			},
			createNewTopic(){
				this.$emit('createNewTopic');
			},
			searchHis(){
				this.$emit('searchHis',this.search_his_words,this.search_by_note_name);
			},
			InitialWords(){
				this.search_his_words='';
				this.searchHis();
			},
			changeShowSearchResult(){
				this.$appState.show_search_results = this.show_search_results;
			}
		},
		}
</script>

<style scoped lang="scss">

</style>
